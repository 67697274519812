export const knxInputMasks = {
  groupAddressMask: {
    mask: "9{1,3}/9{1,3}/9{1,3}"
  },
  maskDecimal: {
    mask: "0|(*{0,1}N[9{1,}])",
    greedy: false,
    definitions: {
      "0": { validator: "0" },
      N: { validator: "[1-9]" },
      "*": { validator: "-" },
    },
  },
  maskBinary: {
    mask: "*",
    greedy: false,
    definitions: {
      '*': { validator: "[01]" }
    }
  },
  mask3Bit: {
    mask: "[*{0,1}k]",
    greedy: false,
    definitions: {
      '*': { validator: "-" },
      'k': { validator: "[0-7]" }
    }
  },
  mask8BitUnsigned: {
    mask: "(0)|([1[9{0,2}]])|([2[l]])|([2[k[9]]])|([2[5[m]]])",
    greedy: false,
    definitions: {
      '0': { validator: "0" },
      '1': { validator: "1" },
      '2': { validator: "2" },
      '5': { validator: "5" },
      'l': { validator: "[5-9]" },
      'k': { validator: "[0-4]" },
      'm': { validator: "[0-5]" },
    }
  },
  mask2ByteUnsigned: {
    mask: "*{1,5}",//"0|(N[9{1,3}])|(M[9{1,4}])|(6[9{1,4}])",
    greedy: false,
    definitions: {
      '0': { validator: "0" },
      'N': { validator: "[1-9]" },
      'M': { validator: "[1-5]" },
      '6': { validator: "6" },
      '*': { validator: "\\b([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])\\b" }
    }
  },
  mask14CharASCII: {
    mask: "A{1,14}",
    greedy: false,
    definitions: {
      // allow all ascii characters from exclamation mark to tilde
      'A': { validator: "[!-~]" },
    }
  },
}