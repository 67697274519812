import { knxInputMasks } from "./knxInputMasks";

export const knxMixin = {
  data: function() {
    return {
      innerSpace: 25,
      variables: {
        setPoint: {
          title: this.$t("accessory.knx.setpoint"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        switchOver: {
          title: this.$t("accessory.knx.switch-over"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        hvacMode: {
          title: this.$t("accessory.knx.hvac-mode"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        offStatus: {
          title: this.$t("accessory.knx.off-status"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        temperature: {
          title: this.$t("accessory.knx.temperature"),
          showWriteAddress: false,
          showReadAddress: true,
        },
        CO2forTemperature: {
          title: this.$t("accessory.knx.CO2forTemperature"),
          showWriteAddress: false,
          showReadAddress: true,
        },
        humidityForTemperature: {
          title: this.$t("accessory.knx.humidityForTemperature"),
          showWriteAddress: false,
          showReadAddress: true,
        },
        airQualityForTemperature: {
          title: this.$t("accessory.knx.airQualityForTemperature"),
          showWriteAddress: false,
          showReadAddress: true,
        },
        onOffState: {
          title: this.$t("accessory.knx.onoff-state"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        deviceLevel: {
          title: this.$t("accessory.knx.device-level"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        actionMove: {
          title: this.$t("accessory.knx.action-move"),
          showWriteAddress: true,
          showReadAddress: false,
        },
        actionStop: {
          title: this.$t("accessory.knx.action-stop"),
          showWriteAddress: true,
          showReadAddress: false,
        },
        actionPunch: {
          title: this.$t("accessory.knx.punch"),
          showWriteAddress: true,
          showReadAddress: false,
        },
        actionOn: {
          title: this.$t("accessory.knx.action-on"),
          showWriteAddress: true,
          showReadAddress: false,
        },
        actionOff: {
          title: this.$t("accessory.knx.action-off"),
          showWriteAddress: true,
          showReadAddress: false,
        },
        colorRed: {
          title: this.$t("accessory.knx.color-red"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        colorGreen: {
          title: this.$t("accessory.knx.color-green"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        colorBlue: {
          title: this.$t("accessory.knx.color-blue"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        colorWhite: {
          title: this.$t("accessory.knx.color-white"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        colorAbsoluteTemperature: {
          title: this.$t("accessory.knx.color-absolute-temperature"),
          showWriteAddress: true,
          showReadAddress: true,
        },
        brightnessValue: {
          title: this.$t("accessory.knx.brightness-value"),
          showWriteAddress: true,
          showReadAddress: true,
        },
      },
      ...knxInputMasks,
      dataPoints: [
        {
          title: "DPT 1 ( 1-Bit 0-1 )",
          value: "1.001",
          mask: knxInputMasks.maskBinary,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT1", value, val);
            return val == 1 || val == 0;
          },
        },
        {
          title: "DPT 3 ( 3-Bit -7...+7 )",
          value: "3.007",
          mask: knxInputMasks.mask3Bit,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT3", value, val);
            return val >= -7 && val <= 7;
          },
        },
        {
          title: "DPT 5 ( 8-Bit Unsigned 5.001 )",
          value: "5.001",
          mask: knxInputMasks.mask8BitUnsigned,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT51", value, val);
            return val >= 0 && val <= 100;
          },
        },
        {
          title: "DPT 5 ( 8-Bit Unsigned 5.004 )",
          value: "5.004",
          mask: knxInputMasks.mask8BitUnsigned,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT54", value, val);
            return val >= 0 && val <= 255;
          },
        },
        {
          title: "DPT 6 ( 8-Bit Signed )",
          value: "6.001",
          mask: knxInputMasks.maskDecimal,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT6", value, val);
            return val >= -128 && val <= 127;
          },
        },
        {
          title: "DPT 7 ( 2-Byte Unsigned )",
          value: "7.001",
          mask: knxInputMasks.mask2ByteUnsigned,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT7", value, val);
            return val >= 0 && val <= 65535;
          },
        },
        {
          title: "DPT 8 ( 2-Byte Signed )",
          value: "8.000",
          mask: knxInputMasks.maskDecimal,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT9", value, val);
            return val >= -32768 && val <= 32767;
          },
        },
        {
          title: "DPT 9 ( 2-Byte Float )",
          value: "9.001",
          mask: knxInputMasks.maskDecimal,
          validator: (value) => {
            const val = parseFloat(value);
            console.log("validator DPT9", value, val);
            return val >= -273 && val <= 670760;
          },
        },
        {
          title: "DPT 12 ( 4-Byte Unsigned )",
          value: "12.001",
          mask: knxInputMasks.maskDecimal,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT12", value, val);
            return val >= 0 && val <= 4294967295;
          },
        },
        {
          title: "DPT 13 ( 4-Byte Signed )",
          value: "13.001",
          mask: knxInputMasks.maskDecimal,
          validator: (value) => {
            const val = parseInt(value);
            console.log("validator DPT13", value, val);
            return val >= -2147483648 && val <= 2147483647;
          },
        },
        {
          title: "DPT 16 ( 14 Char String )",
          value: "16.000",
          mask: knxInputMasks.mask14CharASCII,
          validator: (value) => {
            const val = String(value);
            console.log("validator DPT16", value, val);
            // value should not be undefined, null, or empty string
            return value && val.length <= 14;
          },
        },
      ],
      knxGroupAddressFormat: /^(3[0-1]|2[0-9]|1[0-9]|[0-9])\/([0-7])\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    };
  },

  methods: {
    title(variable) {
      return this.variables[variable].title;
    },

    showWriteAddress(variable) {
      return this.variables[variable].showWriteAddress;
    },

    showReadAddress(variable) {
      return this.variables[variable].showReadAddress;
    },

    getMask(dpt) {
      console.log(dpt)
      if (!dpt) {
        return ""
      }
      console.log(this.dataPoints.find(dp => dp.value === dpt).mask)
      return this.dataPoints.find(dp => dp.value === dpt).mask
    },

    validateKnxGroupAddress(knxGroupAddress) {
      return knxGroupAddress.match(this.knxGroupAddressFormat);
    },

    validateKnxValue(dptCode, value) {
      const dataPoint = this.dataPoints.find((dpt) => dpt.value == dptCode);
      console.log("validateKnxValue", dptCode, value, dataPoint);
      if (!dataPoint) return false;
      return dataPoint.validator(value);
    },
  },
};
